// @codingStandardsIgnoreFile
'use strict'

const EventBus = require('core/modules/eventBus')
const FormErrorBuilder = require('core/modules/formErrorBuilder')

const MembersCe = {
  init: function () {
    this.listen();

    ['booking.remove_travellers'].forEach((eventName) => {
      EventBus.on(eventName, this.listen.bind(this))
    })

    this.mapForm((fisrtnameElm, lastnameElm) => {
      [fisrtnameElm, lastnameElm].forEach(elm => {
        elm.addEventListener('change', this.listen.bind(this))
      })
    })

    return this
  },

  listen: function (e) {
    this.cleanErrors()
    document.querySelectorAll('[data-member]').forEach((elm) => {
      elm.removeEventListener('click', this.click.bind(this))
      elm.addEventListener('click', this.click.bind(this), false)
      this.show(elm)
      if (this.isUsed(elm)) {
        this.hide(elm)
      }
    })
  },

  click: function (event) {
    const elm = event.target
    if (!this.isUsed(elm)) {
      this.insertInForm(elm)
    }
    event.stopPropagation()
  },

  hide: function (elm) {
    elm.style.display = 'none'
  },

  show: function (elm) {
    elm.style.display = null
  },

  isUsed: function (elm) {
    const { firstname, lastname } = this.getData(elm)
    let _isUsed = false
    this.mapForm((_firstname, _lastname) => {
      if (_firstname.value === firstname && _lastname.value === lastname) {
        _isUsed = true
      }
    })

    return _isUsed
  },

  insertInForm: function (elm) {
    const { firstname, lastname, birthdate } = this.getData(elm)

    let freeRow = false

    this.mapForm((_firstname, _lastname) => {
      if (_firstname.value === '' && _lastname.value === '') {
        freeRow = true
      }
    })

    if (!freeRow) {
      try {
        this.addRowInForm()
      } catch (e) {
        this.cleanErrors()
        FormErrorBuilder('data-max-capacity-message', '#add-booking', '.travellers-select')
        return
      }
    }

    let inserted = false
    this.mapForm((_firstname, _lastname, _birthdate) => {
      if (_firstname.value === '' && _lastname.value === '' && !inserted) {
        inserted = true
        _firstname.value = firstname
        _lastname.value = lastname

        _birthdate.day.value = birthdate.day
        _birthdate.month.value = birthdate.month
        _birthdate.year.value = birthdate.year
      }
    })

    this.hide(elm)
  },

  isActive: function () {
    return document.querySelectorAll('[data-member]').length > 0
  },

  getData: function (elm) {
    const birthdate = elm.getAttribute('data-member-birthdate')
    const [year, month, day] = birthdate.split('-')
    return {
      firstname: elm.getAttribute('data-member-firstname'),
      lastname: elm.getAttribute('data-member-lastname'),
      birthdate: {
        year: year === undefined ? null : year,
        month: month === undefined ? null : month,
        day: day === undefined ? null : day
      }
    }
  },

  addRowInForm: function () {
    const travellerNumberElm = document.getElementById('addBooking_travellerNumber')

    let chosen = parseInt(travellerNumberElm.value)
    chosen++

    if (chosen > parseInt(travellerNumberElm.getAttribute('data-travellersmaxnumber'))) {
      throw Error('Impossible to add an another member')
    }

    travellerNumberElm.value = `${chosen}`
    travellerNumberElm.dispatchEvent(new Event('change'))
  },

  mapForm: function (cb) {
    document.querySelectorAll('.travellers-list .form-block').forEach((node, i, list) => {
      const firstname = node.querySelector('input[name$="[firstName]"]')
      const lastname = node.querySelector('input[name$="[lastName]"]')
      const birthday = {
        day: node.querySelector('input[name$="[birthdate][day]"]'),
        month: node.querySelector('input[name$="[birthdate][month]"]'),
        year: node.querySelector('input[name$="[birthdate][year]"]')
      }

      cb(firstname, lastname, birthday)
    })
  },

  cleanErrors: function () {
    document
      .querySelector('.travellers-select')
      .querySelectorAll('.form-error')
      .forEach((err) => err.remove())
  }
}

module.exports = MembersCe.init()
