// @codingStandardsIgnoreFile
'use strict'

require('../modules/booking')

const common = require('core/common')
const EventBus = require('core/modules/eventBus')
const MembersCE = require('core/modules/membersCe')
const Modal = require('core/modules/modal')
const nbAdultMax = document.getElementById('add-booking').getAttribute('data-max-adult')
const adultAge = parseFloat(document.getElementById('add-booking').getAttribute('data-age-adult'))

let inputTab = []

const Booking = {
  addPriceSupplementUrl: null,
  addDiscountCodeUrl: null,
  addCreditCodeUrl: null,
  updateInsuranceUrl: null,
  updateFlexibleOptionUrl: null,
  travellerDetailsClone: null,
  shouldUpdateFirstTraveller: null,
  autocomplete: null,

  init: function (addPriceSupplementUrl, updateInsuranceUrl, addDiscountCodeUrl, addCreditCodeUrl, updateFlexibleOptionUrl) {
    this.addPriceSupplementUrl = addPriceSupplementUrl
    this.addDiscountCodeUrl = addDiscountCodeUrl
    this.addCreditCodeUrl = addCreditCodeUrl
    this.updateInsuranceUrl = updateInsuranceUrl
    this.updateFlexibleOptionUrl = updateFlexibleOptionUrl

    // by default, we can update first traveller
    this.shouldUpdateFirstTraveller = true
    // but in edition, if the contact and the first traveller are not the same (edit draft), we can not update automatically first traveller
    if (
      (document.getElementById('addBooking_contact_lastName').value !== '' && document.getElementById('addBooking_contact_lastName').value !== document.getElementById('addBooking_travellers_0_lastName').value) ||
      (document.getElementById('addBooking_contact_firstName').value !== '' && document.getElementById('addBooking_contact_firstName').value !== document.getElementById('addBooking_travellers_0_firstName').value)
    ) {
      this.shouldUpdateFirstTraveller = false
    }

    this.initAddTraveller()
    this.initTravellerFields()
    this.initRemoveTraveller()
    this.initAddressAutocomplete()
    this.initCheckSelectedTraveller()
    this.initAddCreditCode()
    this.initInsuranceUpdate()
    this.initFlexibleOptionUpdate()
    this.initAddDiscountCode()
    this.initFormSubmit()

    EventBus.on('booking-credit-code-updated', () => {
      this.initRemoveCreditCode()
      this.initCreditCodeModal()
    })

    EventBus.emit('booking-recap-updated')
    EventBus.emit('booking-credit-code-updated')

    this.travellerDetailsClone = document.querySelector('.traveller[data-index="1"]').cloneNode(true)

    document.querySelectorAll('#addBooking_contact_lastName, #addBooking_contact_firstName, #addBooking_contact_birthDate_year, #addBooking_contact_birthDate_month, #addBooking_contact_birthDate_day').forEach(function (el) {
      el.addEventListener('change', function () {
        if (Booking.shouldUpdateFirstTraveller) {
          Booking.setFirstTraveller()
        }
      })
    })

    document.querySelectorAll('#addBooking_travellers_0_lastName, #addBooking_travellers_0_firstName, #addBooking_travellers_0_birthdate_year, #addBooking_travellers_0_birthdate_month, #addBooking_travellers_0_birthdate_day').forEach(function (el) {
      el.addEventListener('change', function () {
        Booking.shouldUpdateFirstTraveller = false
      })
    })

    if ((Booking.androidVersion() !== '' && Booking.androidVersion() < 4.5)) {
      document.querySelectorAll('input').forEach(function (el) {
        el.addEventListener('focus', function (e) {
          e.preventDefault()

          if (el.parentNode.parentNode.parentNode.classList.contains('traveller')) {
            if (el.parentNode.querySelector('.error')) {
              el.parentNode.removeChild(el.parentNode.querySelector('.error'))
            }
          } else {
            if (el.parentNode.parentNode.parentNode.querySelector('.error')) {
              el.parentNode.parentNode.parentNode.removeChild(el.parentNode.parentNode.parentNode.querySelector('.error'))
            }
          }
        })
      })

      document.querySelector('#booking-step1 form').addEventListener('submit', function (e) {
        let emptyFields = 0
        let formResult = true

        document.querySelectorAll('[required]').forEach(function (el) {
          if (el.getAttribute('type') === 'radio') {
            const inputName = el.getAttribute('name')

            if (document.querySelector('input[name="' + inputName + '"]:checked')) {
              emptyFields = emptyFields + 1
            }
          } else if (el.value === '') {
            emptyFields = emptyFields + 1
          }
        })

        if (document.querySelector('input[name="addBooking[contact][gender]"]:checked')) {
          document.querySelector('.gender .error').innerHTML = document.getElementById('add-booking').getAttribute('data-empty-field-message')
        } else {
          document.querySelector('.gender .error').innerHTML = ''
        }

        if (emptyFields !== 0) {
          let scrollTopToEl = null

          e.preventDefault()

          document.querySelectorAll('.error').forEach(function (el) {
            if (el.innerHTML.length > 0 && !scrollTopToEl) {
              scrollTopToEl = el.parentNode
            }
          })

          if (scrollTopToEl) {
            scrollTopToEl.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
          }

          formResult = false
        }

        return formResult
      })
    }

    Booking.initCheckAge()
  },

  initInsuranceUpdate: function () {
    const self = this
    document.querySelectorAll('input[name="addBooking[insurance]"]').forEach(function (el) {
      el.addEventListener('change', function () {
        const xhr = common.createXhr('POST', self.updateInsuranceUrl)

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            const data = JSON.parse(xhr.response)
            EventBus.emit('booking-changed', data)
          }
        })
        const params = {
          insuranceTypeId: this.value
        }
        xhr.send(Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&'))
      })
    })
  },

  initFlexibleOptionUpdate: function () {
    const self = this
    const flexibleOptionInput = document.querySelector('input[name="addBooking[flexibleOption]"]')
    if (!flexibleOptionInput) {
      return
    }

    flexibleOptionInput.addEventListener('change', function () {
      const xhr = common.createXhr('POST', self.updateFlexibleOptionUrl)

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const data = JSON.parse(xhr.response)
          EventBus.emit('booking-changed', data)
        }
      })
      const params = {
        flexibleOption: this.checked ? 1 : 0
      }
      xhr.send(Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&'))
    })
  },

  addTravellerForm: function (parent, travellersNumber) {
    let clone
    const totalTravellers = document.querySelector('.travellers-list').children.length
    let i = totalTravellers

    if (totalTravellers > travellersNumber) {
      for (i; i > travellersNumber; i--) {
        Booking.removeTravellerForm(i)
      }
    } else {
      for (i; i < travellersNumber; i++) {
        clone = this.travellerDetailsClone.cloneNode(true)
        clone.setAttribute('data-index', i + 1)

        clone.querySelectorAll('label').forEach(function (el) { // eslint-disable-line no-loop-func
          if (el.getAttribute('for') !== null) {
            el.setAttribute('for', el.getAttribute('for').replace('_0_', '_' + i + '_'))
          }
        })

        clone.querySelectorAll('div').forEach(function (el) { // eslint-disable-line no-loop-func
          if (el.hasAttribute('id')) {
            el.setAttribute('id', el.getAttribute('id').replace('_0_', '_' + i + '_'))
          }
        })

        clone.querySelectorAll('input, select').forEach(function (el) { // eslint-disable-line no-loop-func
          el.value = ''

          if (el.getAttribute('id') && el.getAttribute('name')) {
            el.setAttribute('id', el.getAttribute('id').replace('_0_', '_' + i + '_'))
            el.setAttribute('name', el.getAttribute('name').replace('[0]', '[' + i + ']'))
          }
        })

        parent.appendChild(clone)
      }
    }
  },

  removeTravellerForm: function (indexToRemove) {
    document.querySelector('.travellers-list').removeChild(document.querySelector('.travellers-list').children[indexToRemove - 1])
    const travellerNodes = document.querySelector('.travellers-list').children

    for (let i = 0; i < travellerNodes.length; i++) {
      const index = travellerNodes[i].getAttribute('data-index')
      if (index < indexToRemove) {
        continue
      }
      travellerNodes[i].setAttribute('data-index', index - 1)

      travellerNodes[i].querySelectorAll('label').forEach(function (el) { // eslint-disable-line no-loop-func
        if (el.hasAttribute('for')) {
          el.setAttribute('for', el.getAttribute('for').replace('_' + (index - 1) + '_', '_' + (index - 2) + '_'))
        }
      })

      travellerNodes[i].querySelectorAll('input, select').forEach(function (el) { // eslint-disable-line no-loop-func
        if (el.getAttribute('id') && el.getAttribute('name')) {
          el.setAttribute('id', el.getAttribute('id').replace('_' + (index - 1) + '_', '_' + (index - 2) + '_'))
          el.setAttribute('name', el.getAttribute('name').replace('[' + (index - 1) + ']', '[' + (index - 2) + ']'))
        }
      })

      travellerNodes[i].querySelectorAll('div').forEach(function (el) { // eslint-disable-line no-loop-func
        if (el.getAttribute('id')) {
          el.setAttribute('id', el.getAttribute('id').replace('_' + (index - 1) + '_', '_' + (index - 2) + '_'))
        }
      })
    }

    EventBus.emit('booking.remove_travellers')
  },

  setFirstTraveller: function () {
    if (document.getElementById('addBooking_contact_firstName').value !== '') {
      const firstNameEl = document.getElementById('addBooking_travellers_0_firstName')
      firstNameEl.value = document.getElementById('addBooking_contact_firstName').value

      if (firstNameEl.parentNode.querySelector('.error')) {
        firstNameEl.parentNode.removeChild(firstNameEl.parentNode.querySelector('.error'))
      }
    }

    if (document.getElementById('addBooking_contact_lastName').value !== '') {
      const lastNameEl = document.getElementById('addBooking_travellers_0_lastName')
      lastNameEl.value = document.getElementById('addBooking_contact_lastName').value

      if (lastNameEl.parentNode.querySelector('.error')) {
        lastNameEl.parentNode.removeChild(lastNameEl.parentNode.querySelector('.error'))
      }
    }

    const birthYear = document.getElementById('addBooking_contact_birthDate_year').value
    let birthMonth = document.getElementById('addBooking_contact_birthDate_month').value
    let birthDay = document.getElementById('addBooking_contact_birthDate_day').value

    const ageFirstTraveller = Booking.getAge(birthYear, birthMonth, birthDay)

    if (ageFirstTraveller !== null && ageFirstTraveller < 18) {
      const formError = document.createElement('p')
      formError.classList.add('form-error')
      formError.innerHTML = document.getElementById('add-booking').getAttribute('data-invalid-age-fist-traveller-message')

      document.querySelector('.birth_date label').appendChild(formError)
    } else if (ageFirstTraveller !== '') {
      if (document.querySelector('.birth_date label').querySelector('.form-error')) {
        document.querySelector('.birth_date label').removeChild(document.querySelector('.birth_date label').querySelector('.form-error'))
      }

      const ageEl = document.getElementById('addBooking_travellers_0_birthdate')
      if (birthDay.length === 1) {
        birthDay = '0' + birthDay
      }
      if (birthMonth.length === 1) {
        birthMonth = '0' + birthMonth
      }
      ageEl.querySelector('#addBooking_travellers_0_birthdate_day').value = birthDay
      ageEl.querySelector('#addBooking_travellers_0_birthdate_month').value = birthMonth
      ageEl.querySelector('#addBooking_travellers_0_birthdate_year').value = birthYear

      if (ageEl.parentNode.parentNode.parentNode.querySelector('.error')) {
        ageEl.parentNode.parentNode.parentNode.removeChild(ageEl.parentNode.parentNode.parentNode.querySelector('.error'))
      }
    }
  },

  initAddTraveller: function () {
    let collectionHolder = document.querySelector('ul.travellers-list')
    collectionHolder.setAttribute('data-index', collectionHolder.children.length)

    document.getElementById('addBooking_travellerNumber').addEventListener('change', function (e) {
      // prevent the link from creating a "#" on the URL
      e.preventDefault()
      collectionHolder = document.querySelector('ul.travellers-list')
      collectionHolder.setAttribute('data-index', collectionHolder.children.length)
      // add a new tag form (see next code block)
      Booking.addPriceSupplement()
      Booking.addTravellerForm(collectionHolder, document.querySelector('#addBooking_travellerNumber option:checked').value)
      if (!MembersCE.isActive()) {
        Booking.setFirstTraveller()
      }
      Booking.initCheckAge()
    })
  },

  initTravellerFields: function () {
    const collectionHolder = document.querySelector('ul.travellers-list')
    collectionHolder.querySelectorAll('input[id$=birthdate_day], input[id$=birthdate_month]').forEach(function (field) {
      if (field.value.length === 1) {
        field.value = '0' + field.value
      }
    })
    collectionHolder.addEventListener('input', function (e) {
      if (e.target.matches('input[id$=birthdate_day], input[id$=birthdate_month]')) {
        if (e.target.value.length === 2) {
          e.target.nextElementSibling.focus()
          e.target.nextElementSibling.select()
        }
      }
    })
  },

  initRemoveTraveller: function () {
    const list = document.querySelector('ul.travellers-list')
    list.addEventListener('click', function (e) {
      if (e.target.classList.contains('traveller-remove')) {
        if (parseInt(document.querySelector('#addBooking_travellerNumber').value) === 1) {
          return
        }
        Booking.removeTravellerForm(e.target.closest('.traveller').getAttribute('data-index'))
        document.querySelector('#addBooking_travellerNumber').value -= 1
        document.querySelector('#addBooking_travellerNumber').dispatchEvent(new CustomEvent('change'))
      }
    })
  },

  initCheckSelectedTraveller: function () {
    document.querySelector('#addBooking_travellerNumber').selectedIndex = document.querySelectorAll('.traveller-checkbox input[type="checkbox"]:checked').length - 1
    document.querySelector('#addBooking_travellerNumber').value = document.querySelectorAll('.traveller-checkbox input[type="checkbox"]:checked').length

    document.querySelectorAll('.traveller-checkbox input[type="checkbox"]').forEach(function (element) {
      Booking.updateCheckSelectedTravellerState(element)

      element.addEventListener('change', function () {
        const nbSelected = document.querySelectorAll('.traveller-checkbox input[type="checkbox"]:checked').length
        if (nbSelected === 0) {
          this.checked = true
        }

        Booking.updateCheckSelectedTravellerState(element)

        document.querySelector('#addBooking_travellerNumber').selectedIndex = nbSelected - 1
        document.querySelector('#addBooking_travellerNumber').value = nbSelected

        Booking.initCheckAge()
      })
    })
  },

  updateCheckSelectedTravellerState: function (travellerElement) {
    const parentElement = travellerElement.closest('.traveller')
    if (travellerElement.checked) {
      parentElement.classList.remove('disabled')
      parentElement.querySelectorAll('.traveller__birthdate input').forEach(function (input) {
        input.required = 'required'
        input.readOnly = ''
      })
    } else {
      parentElement.classList.add('disabled')
      parentElement.querySelectorAll('.traveller__birthdate input').forEach(function (input) {
        input.required = ''
        input.readOnly = 'readonly'
      })
    }
  },

  initCheckAge: function () {
    inputTab = []
    document.querySelectorAll('.traveller[data-index]:not(.disabled) div[id^=addBooking_travellers_][id$=_birthdate]').forEach(function (node, index) {
      inputTab[index] = node.value || -1
      Booking.checkAge(node)

      node.onchange = function () {
        Booking.checkAge(this)
      }
    })
  },

  getAge: function (birthYear, birthMonth, birthDay) {
    const now = new Date()

    if (isNaN(parseInt(birthYear)) || isNaN(parseInt(birthMonth)) || isNaN(parseInt(birthMonth))) {
      return null
    }

    let ageFirstTraveller = now.getFullYear() - parseInt(birthYear) - 1
    if (parseInt(birthMonth) < (now.getMonth() + 1) || (parseInt(birthMonth) === (now.getMonth() + 1) && parseInt(birthDay) <= now.getDate())) {
      ageFirstTraveller += 1
    }

    return ageFirstTraveller
  },

  checkAge: function (node) {
    let nodeIndex, yearNode
    let nbAdult = 0

    if (typeof node !== 'undefined') {
      nodeIndex = node.id.split('_')[2]
      yearNode = node.querySelector('[name=addBooking\\[travellers\\]\\[' + nodeIndex + '\\]\\[birthdate\\]\\[year\\]]')
      const birthYear = yearNode.value
      const birthMonth = node.querySelector('[name=addBooking\\[travellers\\]\\[' + nodeIndex + '\\]\\[birthdate\\]\\[month\\]]').value
      const birthDay = node.querySelector('[name=addBooking\\[travellers\\]\\[' + nodeIndex + '\\]\\[birthdate\\]\\[day\\]]').value
      const age = Booking.getAge(birthYear, birthMonth, birthDay)
      inputTab[nodeIndex] = age !== null ? age : -1
    }

    for (let j = 0; j < inputTab.length; j++) {
      if (inputTab[j] >= adultAge) {
        nbAdult++
      }
    }

    if (nbAdult > nbAdultMax) {
      for (let k = inputTab.length - 1; k >= 0; k--) {
        if (adultAge <= inputTab[k]) {
          if (document.getElementById('age-error') === null) {
            document.querySelector('.travellers-list').insertAdjacentHTML('afterend', '<p class="form-error" id="age-error">' + document.getElementById('add-booking').getAttribute('data-max-adults-number-message') + '</p>')
          }
          break
        }
      }
      document.getElementById('addBooking_save').disabled = true
    } else {
      if (document.getElementById('age-error')) {
        document.getElementById('age-error').parentNode.removeChild(document.getElementById('age-error'))
      }
      document.getElementById('addBooking_save').disabled = false
    }

    if (typeof node !== 'undefined') {
      if (parseInt(nodeIndex, 10) === 0) {
        if (document.getElementById('first-traveller-error')) {
          document.getElementById('first-traveller-error').parentNode.removeChild(document.getElementById('first-traveller-error'))
        }

        if (inputTab[nodeIndex] !== -1 && inputTab[nodeIndex] < 18) {
          const message = document.getElementById('add-booking').getAttribute('data-invalid-age-fist-traveller-message')
          document.querySelector('.travellers-list').insertAdjacentHTML('afterend', '<p class="form-error" id="first-traveller-error">' + message + '</p>')
          yearNode.setCustomValidity(message)
        } else {
          yearNode.setCustomValidity('')
        }
      }
    }
  },

  addPriceSupplement: function () {
    const xhr = common.createXhr('POST', this.addPriceSupplementUrl)

    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const data = JSON.parse(xhr.response)
        EventBus.emit('booking-changed', data)
      }
    })
    xhr.send('quantityOfTraveller=' + document.querySelector('#addBooking_travellerNumber option:checked').value)
  },

  initAddDiscountCode: function () {
    const el = document.getElementById('addBooking_addDiscountCode_addCode')
    if (!el) {
      return
    }
    el.addEventListener('click', e => {
      e.preventDefault()

      const xhr = common.createXhr('POST', this.addDiscountCodeUrl)
      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const data = JSON.parse(xhr.response)
          EventBus.emit('booking-changed', data)

          document.getElementById('addBooking_addDiscountCode_discountCode').value = ''
        }
      })

      xhr.send('discountCodeId=' + document.getElementById('addBooking_addDiscountCode_discountCode').value)
    })
  },

  initAddCreditCode: function () {
    const addButton = document.getElementById('addBooking_creditCode_add')
    const emailInput = document.getElementById('addBooking_contact_email')
    const codeInput = document.getElementById('addBooking_creditCode_code')
    const error = document.getElementsByClassName('credit-code-error')[0]

    if (!addButton) {
      return
    }

    codeInput.addEventListener('keypress', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        addButton.click()
      }
    })

    addButton.addEventListener('click', e => {
      e.preventDefault()

      if (codeInput.value) {
        let postUrl = this.addDiscountCodeUrl
        // workaround because we have discount code or credit code in the same field
        if (codeInput.value.indexOf('AV-') === 0 || codeInput.value.indexOf('GC-') === 0) {
          postUrl = this.addCreditCodeUrl
        }
        const xhr = common.createXhr('POST', postUrl)
        xhr.setRequestHeader('Content-type', 'application/json')

        xhr.addEventListener('readystatechange', function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const data = JSON.parse(xhr.response)
              EventBus.emit('booking-changed', data)

              codeInput.value = ''
              codeInput.classList.remove('has-error')
              error.innerText = null
            } else {
              const data = JSON.parse(xhr.response)
              error.innerText = data.error
              codeInput.classList.add('has-error')
            }
          }
        })

        const data = JSON.stringify({
          email: emailInput.value,
          code: codeInput.value
        })

        xhr.send(data)
      } else {
        codeInput.classList.add('has-error')
      }
    })
  },

  initRemoveCreditCode: function () {
    document.querySelectorAll('.credit-code-remove').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()
        const xhr = common.createXhr('POST', el.getAttribute('data-url'))
        xhr.addEventListener('readystatechange', function () {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            const data = JSON.parse(xhr.response)
            EventBus.emit('booking-changed', data)
          }
        })
        xhr.send()
      })
    })
  },

  initCreditCodeModal: function () {
    const modalContentEl = document.getElementById('credit-code-modal-content')

    if (this.creditCodeModal) {
      this.creditCodeModal.destroy()
      this.creditCodeModal = null
    }

    if (!this.creditCodeModal && modalContentEl) {
      this.creditCodeModal = new Modal({
        class: 'credit-code-modal'
      })
    }

    if (this.creditCodeModal && modalContentEl) {
      this.creditCodeModal.setContent(modalContentEl.innerHTML)

      const modalSubmitButtonEl = this.creditCodeModal.element.querySelector('button[type="submit"]')

      if (modalSubmitButtonEl) {
        modalSubmitButtonEl.addEventListener('click', () => {
          this.creditCodeModal.destroy()
          this.creditCodeModal = null
          document.getElementById('addBooking_save').click()
        })
      }
    }
  },

  androidVersion: function () {
    const deviceAgent = navigator.userAgent
    let version = ''

    if (deviceAgent.indexOf('Android') !== -1) {
      version = parseFloat(deviceAgent.match(/Android\s+([\d\.]+)/)[1]) // eslint-disable-line no-useless-escape
    }

    return version
  },

  initFormSubmit: function () {
    document.getElementById('addBooking_save').addEventListener('click', (e) => {
      const elements = document.getElementById('add-booking').querySelectorAll('input:invalid, select:invalid, textarea:invalid')

      if (elements[0]) {
        elements[0].scrollIntoView()

        if (HTMLFormElement.prototype.reportValidity) {
          elements[0].reportValidity()
        }
      } else {
        if (this.creditCodeModal) {
          e.preventDefault()
          this.creditCodeModal.open()
          return false
        }
      }
    })
  },

  initAddressAutocomplete: function () {
    const countrySelector = document.getElementById('addBooking_contact_countryCode')

    const script = document.createElement('script')
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + formBooking.getAttribute('data-google-places-api') + '&libraries=places'
    script.onload = () => Booking.fillAddress(countrySelector.value.toLowerCase())
    document.head.append(script)

    countrySelector.addEventListener('change', function (event) {
      Booking.fillAddress(event.target.value.toLowerCase())
    })
  },

  fillAddress: function (lang) {
    if (Booking.autocomplete !== null) {
      google.maps.event.clearInstanceListeners(Booking.autocomplete)
    }

    Booking.autocomplete = new google.maps.places.Autocomplete(document.getElementById('addBooking_contact_address'), {
      componentRestrictions: { country: [lang] },
      fields: ['address_components'],
      types: ['address']
    })

    // When the user selects an address from the drop-down, populate the address fields in the form.
    Booking.autocomplete.addListener('place_changed', function () {
      // Get the place details from the autocomplete object.
      const place = Booking.autocomplete.getPlace()
      let address = ''
      let postcode = ''
      let city = ''

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            address = `${component.long_name} ${address}`
            break
          }

          case 'route': {
            address += component.short_name
            break
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            break
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`
            break
          }
          case 'locality':
            city = component.long_name
            break
        }
      }

      document.getElementById('addBooking_contact_address').value = address
      document.getElementById('addBooking_contact_zipCode').value = postcode
      document.getElementById('addBooking_contact_city').value = city
    })
  }
}

const formBooking = document.querySelector('form[name="addBooking"]')
module.exports = Booking.init(
  formBooking.getAttribute('data-add-price-supplement-url'),
  formBooking.getAttribute('data-insurance-update-url'),
  formBooking.getAttribute('data-add-discount-code-url'),
  formBooking.getAttribute('data-add-credit-code-url'),
  formBooking.getAttribute('data-flexible-option-update-url')
)
