// @codingStandardsIgnoreFile
'use strict'

const SearchCalendar = require('core/modules/searchCalendar')

const Search = {
  init: function () {
    this.initCalendar()

    require('core/modules/legacy/searchForm')
  },

  initCalendar: function () {
    this.searchCalendar = new SearchCalendar(document.getElementById('search_offers_checkInDate'))
  }
}

module.exports = Search.init()
