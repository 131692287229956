// @codingStandardsIgnoreFile
'use strict'

const accommodations = require('./product/accommodations')
const sliders = require('./product/sliders')
const CalendarManager = require('core/modules/calendarManager').default

const product = {
  init: function () {
    this.initTab()

    sliders.init()

    accommodations.initCalendarOpen()
    accommodations.showMoreProperties()

    this.initSearchAvailabilityForm()
  },

  initTab: function () {
    document.querySelectorAll('[data-tab-target]').forEach(function (el) {
      el.addEventListener('click', function () {
        const tabName = el.closest('[data-tab-name]').getAttribute('data-tab-name')
        const tabTarget = el.getAttribute('data-tab-target')

        // make tab active
        document.querySelectorAll('[data-tab-name="' + tabName + '"] [data-tab-target]').forEach(function (item) {
          item.classList.remove('tab-active')
        })
        el.classList.add('tab-active')

        // show good tab content
        document.querySelectorAll('[data-tab-content="' + tabName + '"] [data-tab]').forEach(function (item) {
          item.classList.remove('content-active')

          if (tabTarget === item.getAttribute('data-tab')) {
            item.classList.add('content-active')
          }
        })
      })
    })
  },

  initSearchAvailabilityForm: function () {
    const form = document.getElementById('search-availability-form')

    if (form !== null) {
      form.querySelector('select[name=night]').addEventListener('change', function () {
        form.querySelector('input[name=checkInDate]').value = ''
        form.querySelector('input[name=checkInDate]').setAttribute('data-nights', form.querySelector('select[name=night]').value)
        CalendarManager.getCalendar('checkInDate').open()
      })
    }
  }
}

product.init()
module.exports = product // Needed because some apps have to call this function later
