// @codingStandardsIgnoreFile
'use strict'

const FormErrorBuilder = (errorName, sourceMessage, target) => {
  const formError = document.createElement('p')
  formError.classList.add('form-error')
  formError.innerHTML = document.querySelector(sourceMessage).getAttribute(errorName)

  document.querySelector(target).appendChild(formError)
}

module.exports = FormErrorBuilder
