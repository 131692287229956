// @codingStandardsIgnoreFile
'use strict'

const login = {
  init: function () {
    const self = this

    this.getNeededInputsForFormSubmission().forEach(function (element) {
      element.addEventListener('keyup', function () {
        self.toggleFormSubmitButton()
      })
    })
  },
  getNeededInputsForFormSubmission: function () {
    return document.querySelectorAll('#login_username, #login_password')
  },
  toggleFormSubmitButton: function () {
    let isNeededInputsForFormSubmissionSet = true
    this.getNeededInputsForFormSubmission().forEach(function (element) {
      isNeededInputsForFormSubmissionSet &= Boolean(element.value)
    })

    const submitButton = document.getElementById('login_submit')
    if (isNeededInputsForFormSubmissionSet) {
      submitButton.removeAttribute('disabled')
      submitButton.classList.remove('disabled')
    } else {
      submitButton.setAttribute('disabled', 'disabled')
      submitButton.classList.add('disabled')
    }
  }
}

module.exports = login.init()
