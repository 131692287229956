'use strict'

const Autocomplete = require('./autocomplete')

class SearchAutocomplete extends Autocomplete {
  constructor (element, userConfig) {
    const config = {
      callbackSelect: function (value) {
        if (value.type !== 'query') {
          document.getElementById('autocomplete-data').value = JSON.stringify(value)
        }

        if (value.type === 'query') {
          document.getElementById('autocomplete-data').value = ''
        }
      },
      callbackEmptyInput: function () {
        document.getElementById('autocomplete-data').value = ''
      },
      callbackPreRender: function (resultEl, value) {
        const innerHtml = resultEl.innerHTML
        resultEl.setAttribute('data-value', value.name)
        resultEl.innerHTML = ''

        const productNameEl = document.createElement('div')
        productNameEl.className = 'autocomplete-result-name'

        if (value.type === 'query') {
          productNameEl.innerHTML = value.context.text.replace(/".*"/, function (query) {
            return '<mark>' + query + '</mark>'
          })
          resultEl.setAttribute('data-type', value.type)
          resultEl.appendChild(productNameEl)
        } else {
          productNameEl.innerHTML = innerHtml
          resultEl.setAttribute('data-type', value.category)
          resultEl.appendChild(productNameEl)

          if (value.description) {
            const locationEl = document.createElement('div')
            locationEl.className = 'autocomplete-result-localisation'
            locationEl.innerHTML = value.description
            resultEl.appendChild(locationEl)
          }
        }

        return resultEl
      },
      callbackOpen: function () {
        document.body.classList.add('search-form__autocomplete--opened')
      },
      callbackClose: function () {
        const currentlyOpened = this.element.classList.contains('autocomplete-active')
        document.body.classList.remove('search-form__autocomplete--opened')
        this.element.classList.remove('autocomplete-active')

        if (currentlyOpened) {
          this.element.dispatchEvent(new CustomEvent('autocomplete.modal.closed'))
        }
      },
      closeIfMinLengthBelow: false
    }

    super(element, Object.assign({}, config, userConfig))
    this.createCloseButton()
  }

  initEvents () {
    super.initEvents()

    const activateFn = () => {
      const closed = !this.element.classList.contains('autocomplete-active')

      this.element.classList.add('autocomplete-active')
      document.body.classList.add('search-form__autocomplete--opened')

      // for iOS
      this.inputEl.focus()

      setTimeout(() => {
        // for android
        this.inputEl.focus()

        this.events.inputClick()
      }, 250)

      if (closed) {
        this.element.dispatchEvent(new CustomEvent('autocomplete.modal.opened'))
      }
    }

    const inputLabel = this.inputEl.closest('label')

    if (inputLabel) {
      inputLabel.addEventListener('touchstart', () => {
        activateFn()
      })
    }

    this.inputEl.removeEventListener('touchstart', this.events.inputClick)
    this.inputEl.addEventListener('touchstart', () => {
      activateFn()
    })

    this.events.searchInputKeyUp = e => {
      if (
        e.keyCode === 37 || e.keyCode === 39
      ) {
        e.preventDefault() // disable left-right arrows behaviour until better solution
      } else {
        this.events.inputKeyUp(e)
      }
    }

    this.inputEl.removeEventListener('keyup', this.events.inputKeyUp)
    this.inputEl.addEventListener('keyup', this.events.searchInputKeyUp)
  }

  createCloseButton () {
    if (this.element.querySelector('.autocomplete-title')) {
      const closeEl = document.createElement('button')
      closeEl.className = 'autocomplete-close'
      closeEl.innerHTML = '&#10005;'
      closeEl.addEventListener('click', (e) => {
        e.preventDefault()
        this.close()
      })

      this.element.querySelector('.autocomplete-title').appendChild(closeEl)
    }
  }

  displayResults (data) {
    this.resultsEl.innerHTML = ''
    const noResultsMessage = this.inputEl.getAttribute('data-no-results-message')

    this.resultsVisible = true

    // force order of keys
    const dataKeys = ['geo', 'product']
    let hasResults = false
    let numberOfColumns = 0

    const createResults = (value, containerEl) => {
      let resultEl = document.createElement('div')
      resultEl.className = 'autocomplete-result'
      resultEl.innerHTML = value.nameHighlight

      resultEl.addEventListener('click', () => {
        this.inputEl.value = value.name
        this.selectedRow = -1

        if (this.config.callbackSelect) {
          this.config.callbackSelect(value)
        }

        this.close()
      })

      resultEl.addEventListener('mouseover', (e) => {
        const focusedEl = this.resultsEl.getElementsByClassName('autocomplete-result-focus')[0]
        if (focusedEl && e.target !== focusedEl) {
          focusedEl.classList.remove('autocomplete-result-focus')
        }

        resultEl.classList.add('autocomplete-result-focus')

        this.selectedRow = -1
      })

      if (this.config.callbackPreRender) {
        resultEl = this.config.callbackPreRender(resultEl, value)
      }

      containerEl.appendChild(resultEl)
    }

    const createContainer = key => {
      const containerEl = document.createElement('div')
      containerEl.className = `autocomplete-results-${key}`

      if (this.element.hasAttribute(`data-autocomplete-title-${key}`)) {
        const containerTitleEl = document.createElement('div')
        containerTitleEl.className = 'autocomplete-results-title'
        containerTitleEl.innerHTML = this.element.getAttribute(`data-autocomplete-title-${key}`)
        containerEl.appendChild(containerTitleEl)
      }

      return containerEl
    }

    const wrapperEl = document.createElement('div')
    wrapperEl.className = 'autocomplete-results-lists'

    dataKeys.forEach(key => {
      if (
        data.data &&
        data.data[key] &&
        Array.isArray(data.data[key]) &&
        data.data[key].length > 0
      ) {
        if (!hasResults) {
          hasResults = true
        }

        const containerEl = createContainer(key)

        data.data[key].forEach(value => {
          createResults(value, containerEl)
        })

        numberOfColumns++

        if (containerEl) {
          wrapperEl.setAttribute('data-cols', String(numberOfColumns))
          wrapperEl.appendChild(containerEl)
        }
      }
    })

    this.resultsEl.appendChild(wrapperEl)

    if (data.data.meta) {
      const containerEl = createContainer('meta')

      createResults(data.data.meta, containerEl)
      this.resultsEl.appendChild(containerEl)
    }

    if (hasResults) {
      if (this.inputEl.value !== '') {
        this.open()
      }
    } else if (noResultsMessage) {
      const noResultEl = document.createElement('div')
      noResultEl.className = 'autocomplete-result-message'
      noResultEl.innerHTML = noResultsMessage
      this.resultsEl.innerHTML = ''
      this.resultsEl.appendChild(noResultEl)

      this.open()
    }
  }

  getQueryParameters () {
    return super.getQueryParameters()
  }
}

module.exports = SearchAutocomplete
