'use strict'

const common = require('core/common')
const Map = require('./map')

class SearchMap extends Map {
  constructor (element, config, markers) {
    super(element, config, markers)

    this.activeMarker = null
  }

  initMap (markers) {
    super.initMap(markers)

    // don't use the popup event as it will remove the active marker class
    this.map.on('click', (e) => {
      if (!e.originalEvent.target.classList.contains('marker-icon')) {
        this.removeActiveMarker()
      }
    })
  }

  flyTo (longitude, latitude) {
    this.map.flyTo({ center: [longitude, latitude] })

    return this
  }

  setActiveMarker (markerId, openMarkerDetails = true) {
    this.removeActiveMarker()

    const selectedMarker = this.markers.filter(marker => {
      return marker.markerId === markerId
    })[0]

    this.activeMarker = selectedMarker

    this.activeMarker.getElement().classList.add('marker--active')
    if (openMarkerDetails) {
      this.activeMarker.getElement().click()
    }

    return this
  }

  removeActiveMarker () {
    if (this.activeMarker) {
      this.activeMarker.getElement().classList.remove('marker--active')
      this.activeMarker = null

      document.querySelectorAll('.marker-popup').forEach(function (el) {
        el.remove()
      })
    }

    return this
  }

  setMarker (el) {
    const self = this
    const markerId = el.getAttribute('data-id')

    const markerTemplate = `<div class="marker-wrapper">
      <div class="marker-icon"></div>
      <div class="marker-overlay">${this.config.markerProperties.getOverlayText ? this.config.markerProperties.getOverlayText(markerId) : ''}</div>
    </div>`

    if (el.getAttribute('data-latitude') && el.getAttribute('data-longitude')) {
      const markerEl = common.createNodeFromHTML(markerTemplate)
      markerEl.id = 'marker-' + markerId
      markerEl.addEventListener('mouseover', function () { this.classList.add('marker--hover') })
      markerEl.addEventListener('mouseout', function () { this.classList.remove('marker--hover') })
      markerEl.addEventListener('click', function () {
        self.setActiveMarker(markerId)

        if (self.config.markerProperties.onClick) {
          self.config.markerProperties.onClick(markerId)
        }
      })

      const marker = new window.mapboxgl.Marker(markerEl, { anchor: 'bottom' })
        .setLngLat([
          parseFloat(el.getAttribute('data-longitude')),
          parseFloat(el.getAttribute('data-latitude'))
        ])

      if (self.config.markerProperties.getContent) {
        const popup = new window.mapboxgl.Popup({ className: 'marker-popup', offset: { bottom: [0, -50] } })
        popup.setDOMContent(self.config.markerProperties.getContent(markerId))
        popup.setMaxWidth('none')

        marker.setPopup(popup)
      }

      marker.addTo(this.map)

      marker.markerId = markerId

      self.markers.push(marker)

      return marker
    }

    return false
  }
}

module.exports = SearchMap
