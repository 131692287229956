// @codingStandardsIgnoreFile
'use strict'

const ImagesViewer = require('core/modules/imagesViewer')
const CalendarManager = require('core/modules/calendarManager').default
const ProductCalendar = require('core/modules/productCalendar')
const Modal = require('core/modules/modal')
const Product = require('core/pages/product')
const common = require('core/common')
const Map = require('./listing/map')

require('../modules/search')

const search = {
  previousRequest: null,

  init: function () {
    const self = this

    document.querySelectorAll('.search-form').forEach(function (el) {
      el.addEventListener('show-loading', function () {
        self.showLoading()
      })
    })

    document.querySelectorAll('.filters-content fieldset > legend').forEach(function (el) {
      el.addEventListener('click', function () {
        this.parentNode.classList.add('clicked')

        if (!this.parentNode.classList.contains('off')) {
          this.parentNode.classList.add('off')
        } else {
          this.parentNode.classList.remove('off')
        }
      })
    })

    this.initSorting()
    this.initPagination()
    this.toggleBlocks()
    this.initImagesViewers()
    this.initFilters()
    this.initReadMore()
    this.initThematic()
    this.initProduct()
    this.showProperties()
    this.initMap()

    Map.init(this)

    document.addEventListener('click', function (e) {
      const el = e.target.closest('.open-product-detail')
      if (el) {
        e.preventDefault()
        e.stopPropagation()

        let url = null
        if (el.tagName === 'A') {
          url = el.getAttribute('href')
        } else {
          url = el.querySelector('a').getAttribute('href')
        }

        self.previousRequest = self.loadProduct(url, self.previousRequest)
      }

      const mapMarkerEl = e.target.closest('.map_marker')
      if (mapMarkerEl) {
        Map.setActiveMarker(mapMarkerEl.getAttribute('data-id'))
        Map.flyTo(mapMarkerEl.getAttribute('data-longitude'), mapMarkerEl.getAttribute('data-latitude'))
      }
    })
  },

  showLoading: function () {
    document.getElementById('results').scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })

    const resultsContainer = document.querySelector('.results-container')
    if (resultsContainer) {
      resultsContainer.style.display = 'none'
    }

    const productInformation = document.querySelector('.product-information')
    if (productInformation) {
      productInformation.style.display = 'none'
    }

    const resultsLoading = document.querySelector('.results-loading')
    if (resultsLoading) {
      resultsLoading.style.display = 'block'
    }
  },

  toggleBlocks: function () {
    document.querySelectorAll('.toggle-button').forEach(function (el) {
      el.addEventListener('click', function () {
        const el = this
        const targetEl = document.getElementById(el.getAttribute('data-target'))

        if (targetEl) {
          if (!el.getAttribute('data-active')) {
            document.querySelectorAll('.app-default .block-toggle').forEach(function (blockToggleEl) {
              blockToggleEl.setAttribute('data-visible', 'false')
            })

            document.querySelectorAll('app-default .blocks-toggle .toggle-button[data-active="true"]').forEach(function (toggleButtonEl) {
              toggleButtonEl.removeAttribute('data-active')
            })
          }

          if (!targetEl.getAttribute('data-visible')) {
            targetEl.setAttribute('data-visible', 'false')
          }

          if (targetEl.getAttribute('data-visible') === 'true') {
            targetEl.setAttribute('data-visible', 'false')
            el.removeAttribute('data-active')
          } else {
            targetEl.setAttribute('data-visible', 'true')
            el.setAttribute('data-active', 'true')
          }
        }
      })
    })

    document.querySelectorAll('.block-toggle .block-close').forEach(function (el) {
      const parentEl = el.parentNode

      parentEl.setAttribute('data-visible', 'false')
      document.querySelector('.blocks-toggle .toggle-button[data-target="' + parentEl.getAttribute('id') + '"]').removeAttribute('data-active')
    })
  },

  initSorting: function () {
    document.querySelectorAll('.search-form').forEach(function (form) {
      const formName = form.getAttribute('name')
      const sortByEl = document.getElementById(formName + '_sortBy')

      if (sortByEl && sortByEl.nodeName === 'SELECT') {
        sortByEl.addEventListener('change', function () {
          document.forms[formName].submit()
        })
      }
    })

    document.querySelectorAll('.sort-by-container .sort-by li').forEach(function (el) {
      el.addEventListener('click', function () {
        const sortByEl = document.getElementById('search_offers_sortBy')
        sortByEl.value = this.getAttribute('data-value')
        sortByEl.dispatchEvent(new CustomEvent('change'))

        document.querySelector('.sort-by-container').setAttribute('data-visible', 'false')
        document.querySelector('.blocks-toggle .toggle-button[data-active="true"]').removeAttribute('data-active')
      })
    })
  },

  initPagination: function () {
    const form = document.getElementById('search-offers')
    const formName = form.getAttribute('name')

    document.querySelectorAll('.listing-container .pagination .page-prev').forEach(function (pagePrevEl) {
      pagePrevEl.addEventListener('click', function () {
        document.getElementById(formName + '_previousPage').value = 'previousPage'
        document.forms[formName].submit()
      })
    })

    document.querySelectorAll('.listing-container .pagination .page-next').forEach(function (pageNextEl) {
      pageNextEl.addEventListener('click', function () {
        document.getElementById(formName + '_nextPage').value = 'nextPage'
        document.forms[formName].submit()
      })
    })

    document.querySelectorAll('.listing-container .pagination .page-number .page').forEach(function (pageEl) {
      pageEl.addEventListener('click', function () {
        document.getElementById(formName + '_goToPage').value = pageEl.getAttribute('data-page')
        document.forms[formName].submit()
      })
    })
  },

  initImagesViewers: function () {
    let activeImagesViewer

    const toggleFn = (el) => {
      if (
        document.getElementById('images-viewer') &&
        el.getAttribute('data-id') === document.getElementById('images-viewer').getAttribute('data-id')
      ) {
        activeImagesViewer.open()
        activeImagesViewer.goToSlide(el.getAttribute('data-index'))
      } else {
        activeImagesViewer = new ImagesViewer(el.getAttribute('data-id'))
        activeImagesViewer.goToSlide(el.getAttribute('data-index'))
      }
    }

    document.addEventListener('click', function (e) {
      const toggleEl = e.target.closest('.toggle-images-viewer')
      if (toggleEl || e.target.matches('.toggle-images-viewer')) {
        toggleFn(toggleEl || e.target)
      }
    })
  },

  initFilters: function () {
    const toggleSearchOffersResetButton = () => {
      const searchOffersResetBlock = document.querySelector('form[name=search_offers] .filter-reset')
      if (searchOffersResetBlock) {
        if (document.querySelector('.filters-content input[type="checkbox"]:checked, .filters-content input[type="radio"]:checked') !== null) {
          searchOffersResetBlock.classList.remove('hide')
          searchOffersResetBlock.classList.add('btn-block')
        } else {
          searchOffersResetBlock.classList.remove('btn-block')
          searchOffersResetBlock.classList.add('hide')
        }
      }
    }

    document.querySelectorAll('.filters-content input[type="checkbox"], .filters-content input[type="radio"]').forEach(function (el) {
      el.addEventListener('change', function () {
        document.getElementById('search_offers_page').value = 1

        const searchForm = document.querySelector('.search-form')
        const formData = new FormData(searchForm)

        let url = window.location.href

        if (window.location.href.indexOf('?') === -1) {
          url = url + '?facets=1'
        } else {
          url = url + '&facets=1'
        }

        document.querySelectorAll('[data-facet-id]').forEach(facetItem => {
          facetItem.disabled = true
          const facetValue = facetItem.parentNode.querySelector('.filter-value')
          if (!facetValue) {
            return
          }

          facetValue.innerText = null
          facetValue.classList.add('loading-image', 'lcv-icon')
        })

        const xhr = new XMLHttpRequest()
        xhr.open('POST', url)
        xhr.responseType = 'document'
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.addEventListener('readystatechange', function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              document.querySelectorAll('[data-facet-id]').forEach(facetItem => {
                const newValueElement = xhr.response.querySelector('[data-facet-id="' + facetItem.getAttribute('data-facet-id') + '"]')
                const facetValue = facetItem.parentNode.querySelector('.filter-value')

                if (!facetValue) {
                  return
                }

                if (newValueElement) {
                  const newValue = xhr.response.querySelector('[data-facet-id="' + facetItem.getAttribute('data-facet-id') + '"]').getAttribute('data-facet-value')
                  facetItem.setAttribute('data-facet-value', newValue)

                  if (facetItem.checked === false) {
                    facetValue.innerText = newValueElement.parentNode.querySelector('.filter-value').innerText
                  }
                }

                facetValue.classList.remove('loading-image', 'lcv-icon')
                facetItem.disabled = parseInt(facetItem.getAttribute('data-facet-value')) <= 0
              })
            } else {
              document.querySelectorAll('[data-facet-id]').forEach(facetItem => {
                const facetValue = facetItem.parentNode.querySelector('.filter-value')

                if (!facetValue) {
                  return
                }

                facetValue.classList.remove('loading-image', 'lcv-icon')
                facetItem.disabled = false
              })
            }
          }
        })

        xhr.onerror = function () {
          document.querySelectorAll('[data-facet-id]').forEach(facetItem => {
            const facetValue = facetItem.parentNode.querySelector('.filter-value')

            if (!facetValue) {
              return
            }

            facetValue.classList.remove('loading-image', 'lcv-icon')
            facetItem.disabled = false
          })
        }

        xhr.send(formData)

        toggleSearchOffersResetButton()
      })
    })

    document.querySelectorAll('.properties-category__see-more').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()
        const ul = el.parentNode.querySelector('ul')
        if (ul.classList.contains('list__choices__display-all')) {
          ul.classList.remove('list__choices__display-all')
        } else {
          ul.classList.add('list__choices__display-all')
        }
      })
    })

    // in order to prevent to make x api call (resetting facet), just submit the main form.
    const removeLink = document.querySelector('form[name=search_offers] .filter-reset a.remove')
    if (removeLink) {
      removeLink.addEventListener('click', function () {
        document.querySelectorAll('.filters-content input[type="checkbox"]').forEach((el) => {
          el.checked = false
        })
        document.querySelector('form[name="search_offers"]').submit()
      })
    }

    toggleSearchOffersResetButton()
  },

  loadProduct: function (url, previousRequest) {
    const loaderEl = document.querySelector('.ajax-loading')
    const errorBlockEl = document.querySelector('.ajax-error')
    const infoEl = document.querySelector('.product-information')
    const self = this
    const request = null

    if (url) {
      const seeMore = document.querySelector('.see-more-text')
      if (seeMore) {
        seeMore.style.display = 'none'
      }

      loaderEl.style.display = 'block'
      errorBlockEl.style.display = 'none'
      infoEl.innerHTML = ''
      document.body.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })

      if (previousRequest) {
        previousRequest.abort()
      }

      const xhr = common.createXhr('GET', url)
      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            infoEl.innerHTML = xhr.response
            // It is necessary to recreate the <script> elements in order for them to be evaluated
            const innerScripts = infoEl.getElementsByTagName('script')
            for (let i = 0; i < innerScripts.length; i++) {
              const newScript = document.createElement('script')
              const innerScript = innerScripts[i]
              newScript.text = innerScript.innerHTML
              innerScript.parentNode.replaceChild(newScript, innerScript)
            }
            loaderEl.style.display = 'none'
            self.initProduct()
          } else if (xhr.status === 400) {
            loaderEl.style.display = 'none'
            errorBlockEl.style.display = 'block'
          }
        }
      })
      xhr.onerror = function () {
        loaderEl.style.display = 'none'
        errorBlockEl.style.display = 'block'
      }
      xhr.send(null)
    }

    return request
  },

  initProductCalendar: function () {
    CalendarManager.removeCalendar('checkInDate')
    this.calendar = new ProductCalendar(
      document.getElementById('checkInDate'),
      {
        currentLocale: document.body.getAttribute('data-locale').split('_'),
        onChange: () => {
          this.previousRequest = this.loadProduct(this.buildProductUrl(), this.previousRequest)
        }
      }
    )
  },

  showProperties: function () {
    document.addEventListener('click', function (e) {
      if (e.target.matches('.accommodation .show-properties')) {
        e.preventDefault()

        const el = e.target
        el.parentNode.classList.toggle('toggle-opened')
        el.closest('.accommodation-details-footer-properties-wrapper').querySelector('.accommodation-properties').classList.toggle('visible')

        if (!el.getAttribute('data-original-text')) {
          el.setAttribute('data-original-text', el.innerHTML)
        }

        const alternateText = el.getAttribute('data-alternate-text')
        el.setAttribute('data-alternate-text', el.innerHTML)
        el.innerHTML = alternateText
      }
    })
  },

  buildProductUrl: function () {
    const night = parseInt(document.querySelector('.build-url-night').value, 10)
    const checkInDate = document.querySelector('.build-url-checkindate').value
    const link = document.getElementById('search-availability-form-ajax').getAttribute('action')
    const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/
    const params = []

    if (night > 0) {
      params.night = night
    }

    if (checkInDate.match(dateRegex)) {
      params.checkInDate = checkInDate
    }

    let queryString = '?'
    if (link.indexOf('?') !== -1) {
      queryString = '&'
    }

    for (const p in params) {
      if ({}.hasOwnProperty.call(params, p)) {
        queryString += p + '=' + params[p] + '&'
      }
    }

    return link + queryString
  },

  initSearchAvailabilityForm: function () {
    const form = document.getElementById('search-availability-form-ajax')

    if (form !== null) {
      form.querySelector('select[name=night]').addEventListener('change', function () {
        form.querySelector('input[name=checkInDate]').value = ''
        form.querySelector('input[name=checkInDate]').setAttribute('data-nights', form.querySelector('select[name=night]').value)
        CalendarManager.getCalendar('checkInDate').open()
      })
    }
  },

  initReadMore: function () {
    const modal = new Modal({
      class: 'modal-full-text'
    })

    document.querySelectorAll('.toggle-text').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()

        const clone = this.parentNode.parentNode.cloneNode(true)
        const toggleTextEl = clone.querySelector('.toggle-text')
        toggleTextEl.parentNode.removeChild(toggleTextEl)
        const ellipsisEl = clone.querySelector('.ellipsis')
        ellipsisEl.parentNode.removeChild(ellipsisEl)
        clone.querySelector('.more-text').classList.remove('hidden-text')

        modal.setContent(clone.innerHTML)
        modal.open()
      })
    })
  },

  initThematic: function () {
    if (document.querySelector('[data-theme=fixed-dates]') !== null) {
      require('./thematic/fixed-dates')
    }
  },

  initProduct: function () {
    Product.init()
    this.initProductCalendar()
    this.initSearchAvailabilityForm()
  },

  initMap: function () {
    const toggleMap = document.getElementById('toggle-map')
    if (toggleMap) {
      toggleMap.addEventListener('click', function () {
        const alternateText = this.getAttribute('data-alternate-text')
        const currentText = this.innerText

        this.innerText = alternateText
        this.setAttribute('data-alternate-text', currentText)

        if (!this.hasAttribute('data-visible')) {
          this.setAttribute('data-visible', 'true')
          this.dispatchEvent(new CustomEvent('search.map.modal.opened'))
          document.getElementById('map').style.display = 'block'
        } else {
          this.removeAttribute('data-visible')
          this.dispatchEvent(new CustomEvent('search.map.modal.closed'))
          document.getElementById('map').style.display = 'none'
        }
      })
    }
  }
}

module.exports = search.init()
