// @codingStandardsIgnoreFile
'use strict'

const common = require('core/common')

const fixedDatesThematic = {
  init: function () {
    this.handleDepartureDatesMenu()
  },

  handleDepartureDatesMenu: function () {
    const self = this

    self.cacheCurrentTab()

    // links menu for desktop and tablet
    document.querySelectorAll('.departure-date-groups ul a').forEach(function (link) {
      link.addEventListener('click', function (e) {
        e.preventDefault()

        self.updateDepartureDatesMenu(this)

        self.loading()
        self.updateTab(link.href)
      })
    })

    // select for mobile
    document.querySelector('.form-departure-date-groups select').addEventListener('change', function () {
      self.loading()
      self.updateTab(this.value)
    })
  },

  loading: function () {
    document.querySelector('.departure-date-groups').scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })

    document.querySelector('.results-container').style.display = 'none'
    document.querySelector('.results-loading').style.display = 'block'
  },

  updateTab: function (url) {
    const self = this

    if (document.querySelector('[data-cached-for="' + url + '"]') !== null) {
      setTimeout(() => {
        self.displayTab(url)
      }, 400)
    } else {
      const xhr = common.createXhr('GET', url)

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const content = xhr.response

          const newList = document.createElement('div')
          newList.setAttribute('data-cached-for', url)
          newList.innerHTML = content
          document.getElementById('results').appendChild(newList)

          self.displayTab(url)
        }
      }

      xhr.send(null)
    }
  },

  updateDepartureDatesMenu: function (clickedLink) {
    document.querySelectorAll('.departure-date-groups ul li').forEach(function (link) {
      if (link.classList.contains('active')) {
        link.classList.remove('active')
      }
    })

    clickedLink.parentElement.classList.add('active')
  },

  displayTab: function (url) {
    document.querySelectorAll('#results > div').forEach(function (tab) {
      if (tab.classList.contains('cached-tab') === false) {
        tab.classList.add('cached-tab')
      }
    })

    document.getElementById('results-list').removeAttribute('id')

    const tabToShow = document.querySelector('[data-cached-for="' + url + '"]')
    tabToShow.classList.remove('cached-tab')
    tabToShow.setAttribute('id', 'results-list')

    document.querySelector('.results-container').style.display = 'block'
    document.querySelector('.results-loading').style.display = 'none'

    window.history.replaceState(null, null, url)
  },

  cacheCurrentTab: function () {
    if (document.querySelector('.departure-date-groups ul li.active a') !== null) {
      const url = document.querySelector('.departure-date-groups ul li.active a').href

      document.getElementById('results-list').setAttribute('data-cached-for', url)
    }
  }
}

module.exports = fixedDatesThematic.init()
