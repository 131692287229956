// @codingStandardsIgnoreFile
'use strict'

require('flatpickr/dist/flatpickr.css')
require('bootstrap/dist/css/bootstrap.css')
require('swiper/swiper-bundle.css')
require('../../css/base.css')

const common = require('../common')
common.polyfills()

document.addEventListener('DOMContentLoaded', function () {
  const currentPageType = document.body.getAttribute('data-pagetype')

  common.init()

  if (currentPageType) {
    switch (currentPageType) {
      case 'login':
        require('../pages/login')
        break
      case 'homepage':
      case 'check-code':
        require('../pages/homepage')
        break
      case 'listing':
        require('../pages/listing')
        break
      case 'product':
        require('../pages/product')
        break
      case 'booking-step1':
        require('../pages/booking_step1')
        break
      case 'booking-step2':
        require('../pages/booking_step2')
        break
      case 'booking-step3':
        require('../pages/booking_step3')
        break
      default:
        break
    }
  }
})
