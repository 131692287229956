// @codingStandardsIgnoreFile
'use strict'

var { Swiper, Navigation, Keyboard, A11y } = require('swiper')
Swiper.use([Navigation, Keyboard, A11y])

const template = `<div class="images-viewer" id="images-viewer" tabindex="-1">
      <span class="viewer-close lcv-icon"></span>
      <div class="viewer-product-details"></div>
      <div class="slider-container large-images">
          <div class="swiper-container" id="images-viewer-slider">
              <div class="swiper-wrapper"></div>
          </div>
          <span id="images-viewer-slider-prev" class="swiper-button-prev"></span>
          <span id="images-viewer-slider-next" class="swiper-button-next"></span>
      </div>
      <div class="total-images"><span class="current-image-index">1</span>/<span id="total-images"></span></div>
      <div class="slider-container thumbnail-images">
          <div class="swiper-container" id="thumbnails-images-viewer-slider">
              <div class="swiper-wrapper"></div>
          </div>
          <span id="thumbnails-images-viewer-slider-prev" class="swiper-button-prev"></span>
          <span id="thumbnails-images-viewer-slider-next" class="swiper-button-next"></span>
      </div>
  </div>`

class ImagesViewer {
  constructor (id) {
    const self = this

    if (document.getElementById('images-viewer')) {
      document.body.removeChild(document.getElementById('images-viewer'))
    }

    if (window.imagesViewerConf && window.imagesViewerConf[id]) {
      const config = window.imagesViewerConf[id]
      const templateEl = document.createRange().createContextualFragment(template)

      templateEl.querySelector('#total-images').innerHTML = config.images.large.length || 0
      templateEl.querySelector('.viewer-close').innerHTML = config.translations.close

      templateEl.querySelector('.viewer-product-details').appendChild(document.createRange().createContextualFragment(`<h1>${config.title}</h1>`))

      if (config.images && config.images.large) {
        for (let i = 0, j = config.images.large.length; i < j; i++) {
          const slideEl = document.createRange().createContextualFragment(`<div class="swiper-slide"><img src="${config.images.large[i]}" /></div>`)
          templateEl.querySelector('#images-viewer-slider').querySelector('.swiper-wrapper').appendChild(slideEl)
        }
      }

      if (config.images && config.images.thumbnail) {
        for (let i = 0, j = config.images.thumbnail.length; i < j; i++) {
          const slideEl = document.createRange().createContextualFragment(`<div class="swiper-slide"><img src="${config.images.thumbnail[i]}" /></div>`)
          templateEl.querySelector('#thumbnails-images-viewer-slider').querySelector('.swiper-wrapper').appendChild(slideEl)
        }
      }

      templateEl.querySelector('.viewer-close').addEventListener('click', function (e) {
        e.preventDefault()

        self.close()
      })

      document.body.appendChild(templateEl)
      self.element = document.getElementById('images-viewer')
      self.element.setAttribute('data-id', id)
      self.open()

      self.element.addEventListener('keyup', function (e) {
        if (e.keyCode === 27) {
          self.element.querySelector('.viewer-close').click()
        }
      })

      if (config.images && config.images.large) {
        self.largeImagesSlider = new Swiper('#images-viewer-slider', {
          navigation: {
            nextEl: '#images-viewer-slider-next',
            prevEl: '#images-viewer-slider-prev'
          },
          centeredSlides: true,
          a11y: true,
          keyboard: true,
          on: {
            slideChangeTransitionStart: function (swiper) {
              if (self.thumbnailsSlider && typeof swiper.activeIndex !== 'undefined') {
                self.thumbnailsSlider.slideTo(swiper.activeIndex)

                if (document.querySelector('#thumbnails-images-viewer-slider .slide-active')) {
                  document.querySelector('#thumbnails-images-viewer-slider .slide-active').classList.remove('slide-active')
                }

                if (self.thumbnailsSlider.slides[swiper.activeIndex]) {
                  self.thumbnailsSlider.slides[swiper.activeIndex].classList.add('slide-active')
                }

                self.element.querySelector('.total-images .current-image-index').innerHTML = swiper.activeIndex + 1
              }
            }
          }
        })

        self.largeImagesSlider.keyboard.enable()
      }

      if (config.images && config.images.thumbnail) {
        self.thumbnailsSlider = new Swiper('#thumbnails-images-viewer-slider', {
          navigation: {
            nextEl: '#thumbnails-images-viewer-slider-next',
            prevEl: '#thumbnails-images-viewer-slider-prev'
          },
          spaceBetween: 10,
          slidesPerView: 'auto',
          on: {
            init: function (swiper) {
              swiper.slides[0].classList.add('slide-active')
            },
            click: function (swiper) {
              if (self.largeImagesSlider && typeof swiper.clickedIndex !== 'undefined') {
                self.largeImagesSlider.slideTo(swiper.clickedIndex)

                if (document.querySelector('#thumbnails-images-viewer-slider .slide-active')) {
                  document.querySelector('#thumbnails-images-viewer-slider .slide-active').classList.remove('slide-active')
                }

                if (swiper.slides[swiper.clickedIndex]) {
                  swiper.slides[swiper.clickedIndex].classList.add('slide-active')
                }

                self.element.querySelector('.total-images .current-image-index').innerHTML = swiper.clickedIndex + 1
              }
            }
          }
        })
      } else {
        self.element.removeChild(self.element.querySelector('.thumbnail-images'))
      }
    }
  }

  goToSlide (index) {
    if (!index) {
      return
    }

    this.largeImagesSlider.slideTo(index)
  }

  close () {
    this.element.classList.remove('visible')
    this.largeImagesSlider.keyboard.disable()
    document.body.classList.remove('no-scroll')
  }

  open () {
    this.element.classList.add('visible')
    if (this.largeImagesSlider) {
      this.largeImagesSlider.keyboard.enable()
    }
    this.element.focus()
    document.body.classList.add('no-scroll')
  }
}

module.exports = ImagesViewer
