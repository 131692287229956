'use strict'

const common = require('../common')
const CalendarManager = require('./calendarManager').default

class SearchCalendar {
  constructor (element, userConfig = {}) {
    if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else {
      this.element = element
    }

    if (!this.element) {
      return
    }

    this.config = userConfig

    const currentLocale = document.body.getAttribute('data-locale').split('_')
    const config = {
      altInput: true,
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      nextArrow: '',
      prevArrow: '',
      showMonths: common.isMobile() ? 1 : 2,
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates[0]) {
          instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(currentLocale[0] + '-' + currentLocale[1])

          if (this.config.onChange) {
            this.config.onChange(instance.element)
          }
        }
      },
      onReady: (selectedDates, dateStr, instance) => {
        if (selectedDates[0]) {
          instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(currentLocale[0] + '-' + currentLocale[1])
        }
      },
      onOpen: (selectedDates, dateStr, instance) => {
        if (this.config.onOpen) {
          this.config.onOpen(instance.element)
        }
      },
      onClose: (selectedDates, dateStr, instance) => {
        if (this.config.onClose) {
          this.config.onClose(instance.element)
        }
      }
    }

    if (this.config.numberOfMonths) {
      config.showMonths = this.config.numberOfMonths
    }

    if (this.element.hasAttribute('data-select-all-days') === false) {
      config.minDate = 'today'
    }

    const currentYear = new Date().getFullYear()

    if (this.element.hasAttribute('data-mindate')) {
      const date = this.element.getAttribute('data-mindate').split('-')
      config.minDate = `${currentYear}-${Number(date[0])}-${Number(date[1])}`
    }

    if (this.element.hasAttribute('data-maxdate')) {
      const date = this.element.getAttribute('data-maxdate').split('-')
      config.maxDate = `${currentYear}-${Number(date[0])}-${Number(date[1])}`
    }

    CalendarManager.initCalendar(this.element.id, this.element, config, currentLocale[0])
  }
}

module.exports = SearchCalendar
