// @codingStandardsIgnoreFile
'use strict'

const bookingStep2 = {
  init: function () {
    let formAlreadyValidated = false

    if (document.getElementById('more-details')) {
      // not visibile if JS is disabled
      document.getElementById('more-details').style.display = 'block'

      document.getElementById('more-details').addEventListener('click', function (e) {
        e.preventDefault()
        e.stopPropagation()

        document.getElementById('more-details-pop-up').classList.remove('lcv-hidden')
        document.getElementById('more-details-pop-up').classList.add('visible')
      })

      document.querySelector('#more-details-pop-up .close-pop-up').addEventListener('click', function (e) {
        e.preventDefault()

        document.getElementById('more-details-pop-up').classList.remove('visible')
        document.getElementById('more-details-pop-up').classList.add('lcv-hidden')
      })
    }

    document.querySelectorAll('.payment-link').forEach(function (el) {
      el.addEventListener('click', function (e) {
        if (!e.target.classList.contains('no-link')) {
          document.querySelectorAll('.radio-btn').forEach(function (el) {
            el.disabled = true
          })
          this.querySelector('.payment-target').closest('.payment-mode').classList.add('payment-mode-disabled')
          this.querySelector('.payment-target').submit()
        }
      })
    })

    document.querySelectorAll('.payment-target').forEach(function (el) {
      el.addEventListener('submit', function () {
        if (!formAlreadyValidated) {
          formAlreadyValidated = true
          return true
        }

        return false
      })
    })

    document.querySelectorAll('.payment-mode-title').forEach(function (el) {
      el.addEventListener('change', function () {
        const targetId = el.getAttribute('data-target')

        document.querySelectorAll('.payment-choices').forEach(function (el2) {
          const id = el2.getAttribute('id')

          el2.classList.add('lcv-hidden')
          el2.parentNode.classList.remove('init')
          el2.parentNode.classList.add('not-selected')

          if (id === targetId) {
            const targetEl = document.getElementById(targetId)
            targetEl.parentNode.classList.remove('not-selected')
            targetEl.classList.remove('lcv-hidden')
          }
        })
      })
    })
  }
}

module.exports = bookingStep2.init()
