// @codingStandardsIgnoreFile
'use strict'

const SearchAutocomplete = require('../searchAutocomplete')

const SearchForm = {
  fieldsContainer: null,
  name: null,
  form: null,

  init: function (fieldsContainer) {
    var self = this
    this.fieldsContainer = fieldsContainer
    this.form = null
    if (document.getElementById(this.fieldsContainer)) {
      this.form = document.getElementById(this.fieldsContainer).closest('form')
      this.name = this.form.getAttribute('name')
    }

    if (document.getElementById('search_offers_search')) {
      document.getElementById('search_offers_search').addEventListener('click', function (e) {
        e.preventDefault()
        self.form.dispatchEvent(new CustomEvent('show-loading'))
        this.classList.add('submitted')
        const promiseList = []
        self.form.querySelectorAll('.loading-animation').forEach(function (el) {
          const p = new Promise(function (resolve, reject) {
            const listenerFn = function () {
              resolve()
            }

            el.addEventListener('animationstart', listenerFn)
            el.addEventListener('webkitAnimationStart', listenerFn)
            el.addEventListener('oAnimationStart', listenerFn)
            el.addEventListener('MSAnimationStart', listenerFn)
          })
          promiseList.push(p)
        })

        Promise.all(promiseList).then(function () {
          SearchForm.submitForm()
        })
      })
    }

    if (document.getElementById('autocomplete-search')) {
      // eslint-disable-next-line no-new
      new SearchAutocomplete(document.getElementById('autocomplete-search'))
    }
  },

  submitForm: function () {
    document.querySelector('form[name="search_offers"]').submit()
  }
}

module.exports = SearchForm.init('search-form-content')
