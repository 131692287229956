// @codingStandardsIgnoreFile
'use strict'

const CalendarManager = require('core/modules/calendarManager').default

module.exports = {
  initCalendarOpen: function () {
    document.querySelectorAll('.open-calendar').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()

        document.getElementById('booking').scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
        CalendarManager.getCalendar('checkInDate').open()
      })
    })
  },

  showMoreProperties: function () {
    document.querySelectorAll('.accommodation-flags__more').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()

        e.target.closest('.accommodation-flags').querySelectorAll('.accommodation-flags__hidden').forEach(function (item) {
          item.classList.remove('accommodation-flags__hidden')
        })
        e.target.remove()
      })
    })
  }
}
