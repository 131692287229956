// @codingStandardsIgnoreFile
'use strict'

var { Swiper, Navigation, Pagination } = require('swiper')
Swiper.use([Navigation, Pagination])

module.exports = {
  init: function () {
    const swiperPictures = new Swiper('#gallery', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      grabCursor: true,
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      pagination: {
        el: '.swiper-pagination',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          return current + '/' + total
        }
      },
      spaceBetween: 10,
      speed: 1000
    })

    const swiperPicturesThumbs = new Swiper('#gallery-thumbs', {
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      slideToClickedSlide: true,
      slidesPerView: 5,
      spaceBetween: 5,
      touchRatio: 0.2
    })

    swiperPictures.params.control = swiperPicturesThumbs
    swiperPicturesThumbs.params.control = swiperPictures

    return [swiperPictures, swiperPicturesThumbs]
  }
}
