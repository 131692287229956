// @codingStandardsIgnoreFile
'use strict'

const common = require('../common')
const CalendarManager = require('core/modules/calendarManager').default

class ProductCalendar {
  constructor (element, userConfig = {}) {
    if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else {
      this.element = element
    }

    if (!this.element) {
      return
    }

    this.config = userConfig

    this.isCalendarInit = false
    this.currentXhrRequest = null

    this.getFirstAvailableDate()

    const config = {
      altInput: true,
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      nextArrow: '',
      prevArrow: '',
      static: true,
      showMonths: common.isMobile() ? 1 : 2,
      onMonthChange: this.getAvailableDates.bind(this),
      onOpen: this.getAvailableDates.bind(this),
      onReady: (selectedDates, dateStr, instance) => {
        if (selectedDates[0]) {
          instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(this.config.currentLocale[0] + '-' + this.config.currentLocale[1])
        }
      },
      onDayCreate: (dObj, dStr, fp, dayElem) => {
        if (fp.config.enable && fp.config.enable.length > 0 && !dayElem.classList.contains('disabled')) {
          dayElem.classList.add('enabled')
        }
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates[0]) {
          instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(this.config.currentLocale[0] + '-' + this.config.currentLocale[1])
          if (this.config.onChange) {
            this.config.onChange(instance.element)
          }
        }
      }
    }

    if (this.element.hasAttribute('data-select-all-days') === false) {
      config.minDate = 'today'
    }

    const currentYear = new Date().getFullYear()

    if (this.element.hasAttribute('data-mindate')) {
      const date = this.element.getAttribute('data-mindate').split('-')
      config.minDate = `${currentYear}-${Number(date[0])}-${Number(date[1])}`
    }

    if (this.element.hasAttribute('data-maxdate')) {
      const date = this.element.getAttribute('data-maxdate').split('-')
      config.maxDate = `${currentYear}-${Number(date[0])}-${Number(date[1])}`
    }

    CalendarManager.initCalendar(this.element.id, this.element, config, this.config.currentLocale[0])
  }

  getFirstAvailableDate () {
    const ajaxData = {
      productId: this.element.getAttribute('data-productid'),
      night: this.element.getAttribute('data-nights')
    }

    this.currentXhrRequest = common.createXhr('POST', this.element.getAttribute('data-url-find-first-available-offer-date'))
    this.currentXhrRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    this.currentXhrRequest.onreadystatechange = () => {
      if (this.currentXhrRequest.readyState === XMLHttpRequest.DONE && this.currentXhrRequest.status === 200) {
        this.firstDate = JSON.parse(this.currentXhrRequest.response)

        if (this.firstDate === '' || typeof this.firstDate === 'object') {
          this.firstDate = null
        }
      }
    }

    this.currentXhrRequest.send(Object.keys(ajaxData).map(key => `${key}=${ajaxData[key]}`).join('&'))
  }

  getAvailableDates (selectedDates, dateStr, instance) {
    if (selectedDates[0]) {
      instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(this.config.currentLocale[0] + '-' + this.config.currentLocale[1])
    }

    if (!this.isCalendarInit && this.firstDate) {
      instance.jumpToDate(this.firstDate)
    }

    const ajaxData = {
      productId: instance.element.getAttribute('data-productId'),
      night: instance.element.getAttribute('data-nights'),
      numberOfMonths: instance.config.showMonths,
      startDate: this.firstDate && !this.isCalendarInit
        ? this.firstDate
        : `${instance.currentYear}-${('0' + (instance.currentMonth + 1)).slice(-2)}-01`
    }

    if (this.currentXhrRequest && this.currentXhrRequest.readyState < XMLHttpRequest.DONE) {
      this.currentXhrRequest.abort()
    }

    this.currentXhrRequest = common.createXhr('POST', instance.element.getAttribute('data-url-get-available-offers_dates'))
    this.currentXhrRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    this.currentXhrRequest.onreadystatechange = () => {
      if (this.currentXhrRequest.readyState === XMLHttpRequest.DONE && this.currentXhrRequest.status === 200) {
        let dates = JSON.parse(this.currentXhrRequest.response)
        dates = Array.isArray(dates) ? dates : []

        if (!this.isCalendarInit) {
          CalendarManager.getCalendar('checkInDate').set('enable', dates)
          this.isCalendarInit = true
        }

        if (dates.length > 0) {
          CalendarManager.getCalendar('checkInDate').set('enable', dates)
        }

        if (dates[0] !== undefined && instance.config.enable[0] !== undefined && instance.formatDate(instance.config.enable[0], 'Y-m-d') !== dates[0]) {
          CalendarManager.getCalendar('checkInDate').jumpToDate(dates[0])
        }

        if (selectedDates[0]) {
          instance.altInput.value = new Date(selectedDates[0]).toLocaleDateString(this.config.currentLocale[0] + '-' + this.config.currentLocale[1])
        }

        if (dates.length === 0) {
          CalendarManager.getCalendar('checkInDate').set('disable', [() => true])
        }
      }
    }

    this.currentXhrRequest.send(Object.keys(ajaxData).map(key => `${key}=${ajaxData[key]}`).join('&'))
  }
}

module.exports = ProductCalendar
