'use strict'

const common = require('core/common')

class Map {
  constructor (element, config = {}, markers) {
    if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else {
      this.element = element
    }

    const mapboxScript = document.getElementById('mapbox-js')

    if (!mapboxScript || (mapboxScript && !mapboxScript.getAttribute('data-token'))) {
      return
    }

    if (!window.mapboxgl || !this.element) {
      return
    }

    window.mapboxgl.accessToken = mapboxScript.getAttribute('data-token')

    this.markers = []
    this.config = config
    this.initMap(markers)
  }

  initMap (markers) {
    let mapStyle

    this.mapLatitude = this.element.getAttribute('data-latitude')
    this.mapLongitude = this.element.getAttribute('data-longitude')

    switch (this.element.getAttribute('data-maptype')) {
      case 'SATELLITE':
        mapStyle = 'mapbox://styles/mapbox/satellite-v9'
        break
      case 'ROADMAP':
      default:
        mapStyle = 'mapbox://styles/mapbox/outdoors-v11'
    }

    const mapConfig = {
      container: this.element,
      zoom: Number(this.element.getAttribute('data-zoom')),
      style: mapStyle
    }

    if (this.mapLatitude && this.mapLongitude) {
      mapConfig.center = [
        parseFloat(this.mapLongitude),
        parseFloat(this.mapLatitude)
      ]
    }

    const CloseControl = function (callback) {
      this.onAdd = function (map) {
        this.map = map
        this.container = document.createElement('div')
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'

        const button = document.createElement('button')
        button.className = 'mapboxgl-ctrl-close'
        button.type = 'button'
        button.innerHTML = 'X'

        button.addEventListener('click', callback)

        this.container.appendChild(button)
        return this.container
      }

      this.onRemove = function () {
        this.container.parentNode.removeChild(this.container)
        this.map = undefined
      }

      return this
    }

    this.map = new window.mapboxgl.Map(mapConfig)

    if (this.config.closeButton && this.config.closeCallback) {
      this.map.addControl(new CloseControl(this.config.closeCallback))
    }

    this.map.addControl(new window.mapboxgl.NavigationControl())
    setTimeout(() => this.map.resize(), 0)

    this.map.on('load', () => {
      this.map.setLayoutProperty('country-label', 'text-field', ['get', 'name_' + this.element.getAttribute('data-language')])
      this.element.dispatchEvent(new CustomEvent('map-initialized'))
    })

    if (markers) {
      this.setMarkers(markers)
    }
  }

  setMarkers (markers) {
    this.clearMarkers()

    let nodes = []

    if (typeof markers === 'string') {
      nodes = document.querySelectorAll(markers)
    } else {
      nodes = markers
    }

    nodes.forEach(el => {
      this.setMarker(el)
    })

    if (!this.mapLatitude && !this.mapLongitude && this.markers.length > 0) {
      this.fitBounds()
    }

    return this
  }

  clearMarkers () {
    for (let i = 0, j = this.markers.length; i < j; i++) {
      this.markers[i].remove()
    }

    this.markers = []

    return this
  }

  setMarker (el) {
    const self = this

    const markerTemplate = `<div class="marker-wrapper">
      <div class="marker-icon"></div>
    </div>`

    if (el.getAttribute('data-latitude') && el.getAttribute('data-longitude')) {
      const markerEl = common.createNodeFromHTML(markerTemplate)

      const marker = new window.mapboxgl.Marker(markerEl, { anchor: 'bottom' })
        .setLngLat([
          parseFloat(el.getAttribute('data-longitude')),
          parseFloat(el.getAttribute('data-latitude'))
        ])
        .addTo(this.map)

      self.markers.push(marker)

      return marker
    }

    return false
  }

  fitBounds () {
    this.bounds = new window.mapboxgl.LngLatBounds()

    for (let i = 0, j = this.markers.length; i < j; i++) {
      const lnglat = this.markers[i].getLngLat()
      this.bounds.extend(lnglat)
    }

    this.map.setCenter(this.bounds.getCenter())
    this.map.fitBounds(this.bounds, { duration: 0, padding: 100 })

    return this
  }

  getMap () {
    return this.map
  }
}

module.exports = Map
