// @codingStandardsIgnoreFile
'use strict'

require('../../css/pages/homepage.css')

const homepage = {
  init: function () {
    require('../modules/search')
  }
}

module.exports = homepage.init()
