// @codingStandardsIgnoreFile
'use strict'

var { Swiper, Navigation } = require('swiper')
Swiper.use([Navigation])

var common = require('../../common')
var SearchMap = require('core/modules/searchMap')

module.exports = {
  listing: null,

  init: function (listing) {
    this.listing = listing

    const self = this
    self.isMapAvailable = false

    document.querySelectorAll('.toggle-map').forEach(function (el) {
      el.addEventListener('click', function () {
        document.body.classList.toggle('map--visible')

        if (self.isMapAvailable === false) {
          self.initMap('#map-container')

          self.map.setMarkers('.map_marker')

          self.isMapAvailable = true
        }

        if (self.mapSlider) {
          self.mapSlider.update()
          self.mapSlider.slideTo(self.mapSlider.activeIndex, 0, false)
        }
      })
    })

    document.querySelectorAll('.toggle-map').forEach(function (el) {
      el.addEventListener('click', function () {
        document.body.classList.toggle('map--visible')

        if (self.isMapAvailable === false) {
          self.initMap('#map-container')

          self.isMapAvailable = true
        }

        if (self.mapSlider) {
          self.mapSlider.update()
          self.mapSlider.slideTo(self.mapSlider.activeIndex, 0, false)
        }
      })
    })
  },

  setActiveMarker: function (id) {
    const self = this

    if (self.isMapAvailable) {
      self.map.setActiveMarker(id, false)
    }
  },

  flyTo: function (longitude, latitude) {
    const self = this

    if (self.isMapAvailable) {
      self.map.flyTo(longitude, latitude)
    }
  },

  createMapSlides: function (nodes, startIndex) {
    const slides = []
    const slideTmpl = document.getElementById('map-slide-tmpl').innerHTML
    let index = startIndex || 0

    nodes.forEach(function (node) {
      if (node.getAttribute('data-latitude') && node.getAttribute('data-longitude')) {
        const div = document.createElement('div')
        div.innerHTML = slideTmpl.trim()
        const slide = div.firstChild

        const firstOffer = node.querySelector('.accommodations .accommodation:first-child')

        if (firstOffer) {
          slide.setAttribute('data-index', index)
          slide.setAttribute('data-id', node.getAttribute('data-id'))
          slide.setAttribute('data-url', firstOffer.querySelector('.row-target').getAttribute('href'))
          slide
            .querySelector('.slide-content')
            .setAttribute('data-id', node.getAttribute('data-id'))

          if (node.hasAttribute('data-first-image-src')) {
            slide
              .querySelector('.slide-image')
              .innerHTML = '<img src="' + node.getAttribute('data-first-image-src') + '" alt="' + node.getAttribute('data-title') + '" />'
          }

          const discount = firstOffer.querySelector('.price .discount')
          const oldPrice = firstOffer.querySelector('.old-price')
          const priceParentEl = slide.querySelector('.slide-details .old-price').parentNode

          if (oldPrice && oldPrice.innerHTML.trim()) {
            slide.querySelector('.slide-details .old-price').innerHTML = oldPrice.innerHTML
          } else {
            priceParentEl.removeChild(slide.querySelector('.slide-details .old-price'))
          }

          if (discount) {
            slide.querySelector('.slide-details .discount').innerHTML = discount.innerHTML
          } else {
            priceParentEl.removeChild(slide.querySelector('.slide-details .discount'))
          }

          let starsHtml = ''
          if (node.querySelector('.name .stars')) {
            starsHtml = node.querySelector('.name .stars').outerHTML
          }

          slide
            .querySelector('.slide-details .name')
            .innerHTML = node.querySelector('.name a').innerText + starsHtml

          slide
            .querySelector('.slide-details .price')
            .innerHTML = firstOffer.querySelector('.price').innerHTML

          slides.push(slide)

          index = index + 1
        }
      }
    })

    return slides
  },

  initMap: function (containerSelector) {
    const self = this
    const sliderWrapper = document.querySelector('#map-products-slider .swiper-wrapper')
    const slides = self.createMapSlides(document.querySelectorAll('#results-list .result'), 0)

    slides.forEach(function (slide) {
      sliderWrapper.appendChild(slide)
    })

    self.mapSlider = new Swiper('#map-products-slider', {
      navigation: {
        nextEl: '.swiper-pictures-button-next',
        prevEl: '.swiper-pictures-button-prev'
      },
      grabCursor: true,
      spaceBetween: 10,
      centeredSlides: true,
      speed: 1000,
      slidesPerView: 'auto'
    })

    self.map = new SearchMap(
      containerSelector,
      {
        markerProperties: {
          getContent: function (markerId) {
            const node = document.querySelector('.slide-content[data-id="' + markerId + '"]').cloneNode(true)

            node.addEventListener('click', function () {
              const url = document.querySelector('.swiper-slide[data-id="' + this.getAttribute('data-id') + '"]').getAttribute('data-url')

              if (url) {
                self.listing.previousRequest = self.listing.loadProduct(url, self.listing.previousRequest)
              }
            })

            return node
          },
          onClick: function (markerId) {
            if (!common.queryMedia('small').matches) {
              return true
            }

            const slideEl = document.querySelector('.swiper-slide[data-id="' + markerId + '"]:not(.swiper-slide-duplicate)')
            self.mapSlider.slideTo(slideEl.getAttribute('data-index'))

            return false
          },
          getOverlayText: function (markerId) {
            return document.querySelector('.slide-content[data-id="' + markerId + '"] .price').innerHTML
          }
        }
      },
      '.map_marker'
    )
  }
}
